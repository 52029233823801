import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class ReportService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getReport(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getReport, { params: args })
    }
}

function useReportService() {
  const reportService = new ReportService()

  return {
    reportService,
  }
}

const { reportService } = useReportService()
export default reportService
