import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'anio', label: 'Año', sortable: true },
    { key: 'mes', sortable: true },
    { key: 'linea', sortable: true },
    { key: 'modelo', sortable: true, formatter: val => `$${val}` },
    { key: '_Base', sortable: true },
    { key: 'marca', sortable: true },
    { key: 'temporada', sortable: true },
    { key: 'paresFacturados', sortable: true },
    { key: 'importeVenta', sortable: true, formatter: val => `$${val}` },
    { key: 'importeCompra', sortable: true, formatter: val => `$${val}` },
    { key: 'precioCompraPromedio', sortable: true, formatter: val => `$${val}` },
    { key: 'gastosFijos', sortable: true, formatter: val => `$${val}` },
    { key: 'descuentos', sortable: true, formatter: val => `$${val}` },
    { key: 'devoluciones', sortable: true, formatter: val => `$${val}` },
    { key: 'mc', sortable: true, formatter: val => `$${val}` },
    { key: 'pe', sortable: true, formatter: val => `$${val}` },
    { key: 'costo', sortable: true, formatter: val => `$${val}` },
    { key: 'utilidad', sortable: true, formatter: val => `$${val}` },
    { key: 'actions' },
  ]
  const perPage = ref(25)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('anio')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const loader = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('app-invoice/fetchInvoices', {
        // q: searchQuery.value,
        // perPage: perPage.value,
        // page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        // status: statusFilter.value,
        fechaInicial: '2023/10/01',
        fechaFinal: '2023/10/10',
        tipo: 'P',
        empcod: 0,
        grucod: 0,
      })
      .then(response => {
        loader.value = false
        const { data } = response
        callback(data)
        totalInvoices.value = data.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // const fetchInvoices = (ctx, callback) => {
  //   const invoices = [
  //     {
  //       id: 4987,
  //       issuedDate: '13 Dec 2019',
  //       client: {
  //         address: '7777 Mendez Plains',
  //         company: 'Hall-Robbins PLC',
  //         companyEmail: 'don85@johnson.com',
  //         country: 'USA',
  //         contact: '(616) 865-4180',
  //         name: 'Jordan Stevenson',
  //       },
  //       service: 'Software Development',
  //       total: 3428,
  //       avatar: '',
  //       invoiceStatus: 'Paid',
  //       balance: '$724',
  //       dueDate: '23 Apr 2019',
  //     },
  //     {
  //       id: 4988,
  //       issuedDate: '17 Jul 2019',
  //       client: {
  //         address: '04033 Wesley Wall Apt. 961',
  //         company: 'Mccann LLC and Sons',
  //         companyEmail: 'brenda49@taylor.info',
  //         country: 'Haiti',
  //         contact: '(226) 204-8287',
  //         name: 'Stephanie Burns',
  //       },
  //       service: 'UI/UX Design & Development',
  //       total: 5219,
  //       invoiceStatus: 'Downloaded',
  //       balance: 0,
  //       dueDate: '15 Dec 2019',
  //     },
  //     {
  //       id: 4989,
  //       issuedDate: '19 Oct 2019',
  //       client: {
  //         address: '5345 Robert Squares',
  //         company: 'Leonard-Garcia and Sons',
  //         companyEmail: 'smithtiffany@powers.com',
  //         country: 'Denmark',
  //         contact: '(955) 676-1076',
  //         name: 'Tony Herrera',
  //       },
  //       service: 'Unlimited Extended License',
  //       total: 3719,
  //       invoiceStatus: 'Paid',
  //       balance: 0,
  //       dueDate: '03 Nov 2019',
  //     },
  //     {
  //       id: 4990,
  //       issuedDate: '06 Mar 2020',
  //       client: {
  //         address: '19022 Clark Parks Suite 149',
  //         company: 'Smith, Miller and Henry LLC',
  //         companyEmail: 'mejiageorge@lee-perez.com',
  //         country: 'Cambodia',
  //         contact: '(832) 323-6914',
  //         name: 'Kevin Patton',
  //       },
  //       service: 'Software Development',
  //       total: 4749,
  //       invoiceStatus: 'Sent',
  //       balance: 0,
  //       dueDate: '11 Feb 2020',
  //     },
  //   ]
  //   callback(invoices)
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    loader,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}
